
/* eslint-disable */

import { DatePicker } from "v-calendar";
import { VxeGridInstance, VxeTablePropTypes, VXETable } from "vxe-table";
import { HotelEventDto } from "@/models/data";
import moment from "moment";
import CloudFun, {
  Condition,
  defineComponent,
  LogicalConnective,
  Operator,
  reactive,
  ref,
  Sorting,
  SortOrder,
} from "@cloudfun/core";
import { useRoute } from "vue-router";
import { nextTick } from "vue";

export default defineComponent({
  components: {
    DatePicker,
  },
  setup() {
    const route = useRoute();
    const model = CloudFun.current?.model;
    const grid = ref({} as VxeGridInstance);
    const events = ref([] as HotelEventDto[]);
    const profitRate = ref();

    const params = reactive({
      insertRows: Array<any>(),
    });
    const range = ref({
      start: moment(new Date()).format("YYYY-MM-DD"),
      end: moment(new Date()).format("YYYY-MM-DD"),
    });
    const condictions = ref<Condition[]>([]);

    const formateDate = (date: Date) => {
      return moment(date).format("YYYY-MM-DD");
    };

    const rowChange = (value: any, e: any) => {
      console.log("rowChange", value);
      const record: any = grid.value.getActiveRecord();
      console.log("current row", record);
      if (profitRate.value && record.row.Cost > 0) {
        record.row.ProfitRate = profitRate.value;
        record.row.SalePrice = record.row.Cost * (1 + profitRate.value / 100);
        record.row.ProfitAmount = record.row.SalePrice - record.row.Cost;
      }
    };

    const costChange = (el: any, e: any) => {
      console.log("costChange", el.value);
      const record: any = grid.value.getActiveRecord();
      console.log("record", record.row);
      record.row.Cost = el.value;
      if (record.row && profitRate.value && el.value > 0) {
        record.row.ProfitRate = profitRate.value;
        record.row.SalePrice = Math.round(
          el.value * (1 + profitRate.value / 100)
        );
        record.row.ProfitAmount = record.row.SalePrice - el.value;
        console.log("item", record.row);
      }
    };

    const priceChange = (el: any, e: any) => {
      console.log("priceChange", el.value);
      const record: any = grid.value.getActiveRecord();
      console.log("record", record.row);
      record.row.SalePrice = el.value;
      if (record.row && record.row.Cost && el.value > 0) {
        record.row.ProfitRate = Math.round(
          ((el.value - record.row.Cost) / record.row.Cost) * 100
        );
        record.row.ProfitAmount = el.value - record.row.Cost;
        console.log("item", record.row);
      }
    };

    const rateChange = async (el: any, e: any) => {
      await nextTick();
      console.log("rateChange", el.value);
      const record: any = grid.value.getActiveRecord();
      console.log("record", record.row);
      record.row.ProfitRate = el.value;
      if (record.row && record.row.Cost && el.value > 0) {
        record.row.SalePrice = Math.round(
          record.row.Cost * (1 + record.row.ProfitRate / 100)
        );
        record.row.ProfitAmount = record.row.SalePrice - record.row.Cost;
        console.log("item", record.row);
      }
    };

    const validRules = ref({
      Cost: [
        {
          type: "number",
          min: 0,
          message: "輸入成本",
        },
        { required: true, message: "成本必須填寫" },
      ],
      Price: [
        {
          type: "number",
          min: 0,
          message: "輸入銷售價格",
        },
        { required: true, message: "銷售價格必須填寫" },
      ],
      FixedPrice: [
        {
          type: "number",
          min: 0,
          message: "輸入定價",
        },
        { required: true, message: "定價必須填寫" },
      ],
      ProfitRate: [
        {
          type: "number",
          min: 0,
          max: 100,
          message: "輸入 0 ~ 100 範圍",
        },
        { required: true, message: "利潤率必須填寫" },
      ],
      SalesQuantity: [{ required: true, message: "庫存必須填寫" }],
    } as VxeTablePropTypes.EditRules);

    const gridOptions = reactive({
      keepSource: true,
      round: true,
      border: true,
      stripe: true,
      highlightHoverColumn: true,
      highlightHoverRow: true,
      resizable: false,
      width: "100%",
      loading: false,
      customConfig: { storage: true },
      editRules: validRules,
      editConfig: {
        trigger: "click",
        enabled: true,
        mode: "row",
        showStatus: true,
      },
      columns: [
        {
          field: "Name",
          title: "房型名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          width: "auto",
        },
        {
          field: "MarketPrice",
          title: "定價",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
            },
          },
        },
        {
          field: "Cost",
          title: "成本",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
              onChange: costChange,
            },
          },
        },
        {
          field: "SalePrice",
          title: "銷售價格",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
              onChange: priceChange,
            },
          },
        },
        {
          field: "ProfitRate",
          title: "利潤率(%)",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
              onChange: rateChange,
            },
          },
        },
        {
          field: "ProfitAmount",
          title: "利潤金額",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          // editRender: {
          //   name: "$input",
          //   autofocus: ".vxe-input--inner",
          //   placeholder: "請點擊輸入...",
          //   props: {
          //     clearable: true,
          //     type: "integer",
          //     min: 0,
          //     align: "right",
          //     readonly: "true",
          //   },
          // },
        },
        {
          field: "StockQty",
          title: "庫存",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: false,
          editRender: {
            name: "$input",
            autofocus: ".vxe-input--inner",
            placeholder: "請點擊輸入...",
            props: {
              clearable: true,
              type: "integer",
              min: 0,
              align: "right",
            },
          },
        },
      ],
      proxyConfig: {
        message: false,
        sort: false,
        filter: false,
        props: { result: "data", total: "totalCount" },
        ajax: {
          query: (params: any) => {
            // condictions.value.push(
            //   new Condition("HotelId", Operator.Equal, route.query.id)
            // );

            // if (route.query.id) {
            //   new Condition("HotelRoomId", Operator.Equal, route.query.id);
            // }

            if (route.query.id) {
              condictions.value.push(
                new Condition("Id", Operator.Equal, route.query.id)
              );
            }

            const queryParams: {
              page: number;
              pageSize: number;
              keyword: string;
              sortings?: Sorting[];
              condition: Condition;
            } = {
              page: params.page.currentPage,
              pageSize: params.page.pageSize,
              keyword: "",
              sortings: params.sorts
                .filter((e: any) => e.property)
                .map(
                  (e: any) =>
                    new Sorting(
                      e.property,
                      e.order === "desc"
                        ? SortOrder.Descending
                        : SortOrder.Ascending
                    )
                ), // sorts
              condition: new Condition(condictions.value), // custom filters
            };

            const filters = params.filters
              ? params.filters.filter((e: any) => e.values.length)
              : null;

            if (filters && filters.length) {
              const gridCondition = new Condition();
              // colum filters
              filters.forEach((filter: any) => {
                const columnCondition = new Condition();
                filter.values.forEach((subFilter: any) => {
                  if (subFilter && subFilter instanceof Condition) {
                    const condition = subFilter as Condition;
                    if (condition.connective === LogicalConnective.And)
                      columnCondition.and(condition);
                    else columnCondition.or(condition);
                  }
                });
                gridCondition.and(columnCondition);
              });
              queryParams.condition.and(gridCondition);
            }

            return new Promise((resolve, reject) =>
              model?.dispatch("hotelRoomSaleItem/load", queryParams).then(
                (payload) => {
                  console.log("payload", payload);
                  events.value.length = 0;
                  if (payload) {
                    events.value = payload.map((item: any) => {
                      return {
                        Id: 0,
                        HotelRoomSaleItemId: item.Id,
                        Name: item.Restriction,
                        MarketPrice: item.FixedPrice,
                        // HotelId: item.HotelId,
                        // HotelName: item.Hotel.Name,
                      };
                    });
                  }

                  console.log("events", events.value);
                  resolve(events.value);
                },
                (reason) => {
                  CloudFun.send("error", {
                    subject: "讀取失敗",
                    content: reason,
                  });
                  reject(reason);
                }
              )
            );
          },
        },
      },
    });

    return {
      range,
      gridOptions,
      events,
      formateDate,
      params,
      profitRate,
      rowChange,
      costChange,
      grid,
      priceChange,
      validRules,
    };
  },
  methods: {
    back() {
      this.$router.back();
    },
    async save() {
      const $table = this.grid;
      const errMap = await $table.validate(true);
      if (errMap) {
        VXETable.modal.message({ status: "error", message: "驗證失敗！" });
      } else {
        VXETable.modal.message({ status: "success", message: "驗證成功！" });

        console.log("rooms", this.events);
        this.params.insertRows = [];
        this.events.forEach((item: HotelEventDto) => {
          if (item.MarketPrice && item.SalePrice) {
            item.StartTime = new Date(this.range.start);
            item.EndTime = new Date(this.range.end);
            this.params.insertRows.push(item);
          }
        });

        console.log("params", this.params);

        this.$model
          .dispatch("hotelEvent/batchCreate", this.params)
          .then(
            (resp) => {
              console.log("update success", resp);
              CloudFun.send("info", {
                subject: "儲存成功",
                content: "飯店資料更新完成",
              });

              this.$router.back();
            },
            (failure) => {
              console.log("update error", failure);
              CloudFun.send("error", {
                subject: "操作失敗！",
                content: failure,
              });
            }
          )
          .finally();
      }
    },
  },
});
